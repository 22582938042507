import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  PLATFORM_ID,
  SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode } from 'libphonenumber-js';
import * as _ from 'lodash';
import Countries from 'src/assets/data/countries.json';
import { sortProp } from '../components/sort/sort.component';
import { authConfig } from '../configs/auth.config';
import { dialogEntities } from '../configs/dialog.config';
import { ColorOptions } from '../interfaces/colors.interface';
import { Country } from '../interfaces/country.interface';
import { CheckFileSizeProp } from '../interfaces/file.interface';
import { Photo, Video } from '../interfaces/media.interface';
import { SelectedRowData } from '../interfaces/table.interface';
import {
  DefaultParamsValuePair,
  DropdownValuePair,
  FAQ,
} from '../models/base.model';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(
    private translateService: TranslateService,
    private router: Router,
    public sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) platformId: string,
    private dialogService: DialogService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  isBrowser = false;
  isServer = true;

  setSystemLanguage() {
    if (!this.isBrowser) return;

    const storedLanguage = localStorage.getItem('LANGUAGE');

    if (storedLanguage && storedLanguage.length > 0) {
      return this.translateService.use(this.parseLanguage(storedLanguage));
    }

    const deviceLanguage = window.navigator.language;
    localStorage.setItem('LANGUAGE', deviceLanguage);

    return this.translateService.use(this.parseLanguage(deviceLanguage));
  }

  changeLanguage(language: string) {
    localStorage.setItem('LANGUAGE', language);
    this.translateService.use(this.parseLanguage(language));
  }

  parseLanguage(language: string): string {
    return language.split('-')[0];
  }

  removeDuplicateObjectByKey(array: any[] = [], key: string = 'id') {
    return [...new Map(array.map((item) => [item[key], item])).values()];
  }

  parseFaqs(faqs: FAQ[] = []): FAQ[] {
    const parsedFaqs = faqs?.map((faq: any) => {
      const faq_details = faq.faq_details.map((detail: any, index: number) => {
        const media = detail.uploads.map((upload: any) => upload.storage_url);

        return { ...detail, active: index == 0 ? true : false, media };
      });

      return { ...faq, faq_details };
    });

    return parsedFaqs ?? [];
  }

  getAccronym(value: any): string {
    let returnValue = '';
    if (!this.isEmptyOrNull(value)) {
      const stringValueArray = String(value).trim().split(' ');
      stringValueArray.forEach((item) => {
        returnValue += item.charAt(0).toUpperCase();
      });
      return returnValue;
    } else return returnValue;
  }

  /* Translate method */
  translate(key: string): string {
    let value = '';
    this.translateService.get(key).subscribe((data) => {
      value = data;
    });
    return value;
  }

  /* ROYGBIV method */
  getRandomColor(colorObject: ColorOptions, index?: number) {
    const values = Object.values(colorObject);
    if (index === undefined) {
      const prop = values[Math.floor(Math.random() * values.length)];
      return prop;
    } else return values[index] ? values[index] : values[0];
  }

  get _lodashRef() {
    return _;
  }

  getMonthsAhead() {
    const currentMonth = new Date().getMonth();
    const months = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', { month: 'long' });
    });

    return months.slice(currentMonth + 1);
  }

  sanitizedUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  sanitizedUrlSecurity(url: string) {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }

  detectBrowser(): string {
    const userAgent = navigator.userAgent;
    let browserName = '';

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }

    return browserName;
  }

  // test if a string value is null, undefined or empty
  isEmptyOrNull(value: any) {
    if (
      typeof value !== 'number' &&
      (value == '' ||
        value == null ||
        value == undefined ||
        value == 'undefined' ||
        Object.keys(Object(value)).length == 0)
    ) {
      return true;
    }
    return false;
  }

  stringToBoolean(value?: string): boolean {
    if (value) {
      const numberRepr = Number(value);
      if (!Number.isNaN(numberRepr)) {
        return Boolean(numberRepr);
      } else {
        return Boolean(value);
      }
    } else return false;
  }

  /** Delete empty entries or trim entries
   * @param {any} input Request object
   * @param {any} blacklist Array of keys to delete NOTE use this only on edit/update requests
   */
  sanitiseRequestObject(
    input: any,
    blacklist: string[] = [],
    keyToDelete?: string,
    keyToTrim?: string,
    deleteInput?: boolean
  ) {
    Object.keys(input).forEach((key, index) => {
      if (blacklist.length > 0) {
        if (
          (typeof input[key] == 'string' || typeof input[key] == 'object') &&
          this.isEmptyOrNull(input[key]) &&
          blacklist.includes(key)
        ) {
          delete input[key];
        }
      } else {
        if (
          (typeof input[key] == 'string' || typeof input[key] == 'object') &&
          this.isEmptyOrNull(input[key])
        ) {
          delete input[key];
        }
      }

      if (
        typeof input[key] == 'string' &&
        this.isEmptyOrNull(input[key]) &&
        deleteInput == true
      ) {
        Object.keys(input).splice(index, 1);
      }
      if (key == keyToTrim && typeof input[key] == 'string') {
        const trimmedValue = String(input[key]).replace(/\s/g, '');
        input[key] = trimmedValue;
      }
      if (key == keyToDelete) {
        delete input[key];
      }
    });
    return input;
  }

  getFileDataFromBase64(base64: string) {
    const fileData = base64.split(',')[1];
    return fileData;
  }

  getBase64FromBlob(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }

  openUrl(
    url: string,
    target: string | null = '_blank',
    rel: string | null = 'nofollow'
  ): void {
    const anchorElement = document.createElement('a');

    anchorElement.href = url;
    document.body.appendChild(anchorElement);

    if (target) anchorElement.target = target;
    if (rel) anchorElement.rel = rel;

    anchorElement.click();
    document.body.removeChild(anchorElement);
  }

  goBack(fallbackPath: string, redirectPath?: string) {
    if (redirectPath) this.router.navigate([redirectPath]);
    else if (history.length > 2) history.back();
    else this.router.navigate([fallbackPath]);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  formatCompactNumber(value: string): string {
    const regex = /[+-]?\d+(\.\d+)?/g;
    let number: any =
      value.match(regex)?.map(function (v) {
        return parseFloat(v);
      }) || '';
    number = Number(number.join(''));
    const formatter = Intl.NumberFormat('en', {
      notation: 'compact',
      maximumSignificantDigits: 3,
    });
    return formatter.format(number);
  }

  setOrderByOptions(
    orderBy: DropdownValuePair[],
    directions: DropdownValuePair[]
  ) {
    const options: sortProp[] = [];
    orderBy.forEach((order) => {
      const tempDirections: any[] = [];
      directions.forEach((direction) => {
        tempDirections.push({
          value: `${order.value}:${direction.value}`,
          label: direction.label,
        });
      });
      options.push({
        value: order.value.toString(),
        label: order.label,
        options: tempDirections,
      });
    });
    return options;
  }

  getDefaultFilterParams(options?: DefaultParamsValuePair[]): object {
    const params: any = {};
    options?.forEach((option) => {
      params[option.name] = option.value;
    });
    return params;
  }

  canRestore(itemsToDelete: { deleteType: string }[]): boolean {
    const hasSoftDelete =
      itemsToDelete.filter((item: any) => item.deleteType === 'soft-delete')
        .length > 0
        ? true
        : false;
    const hasHardDelete =
      itemsToDelete.filter((item: any) => item.deleteType === 'hard-delete')
        .length > 0
        ? true
        : false;
    const hardDelete = itemsToDelete.filter(
      (item: any) => item.deleteType === 'hard-delete'
    );

    if (hasHardDelete && hasSoftDelete) {
      return false;
    }
    if (
      hasHardDelete !== hasSoftDelete &&
      itemsToDelete.length === hardDelete.length
    ) {
      return true;
    }
    return false;
  }

  canDelete(selectedItems: { deleteType: string }[]) {
    const hasSoftDelete =
      selectedItems.filter((item: any) => item.deleteType === 'soft-delete')
        .length > 0
        ? true
        : false;
    const hasHardDelete =
      selectedItems.filter((item: any) => item.deleteType === 'hard-delete')
        .length > 0
        ? true
        : false;

    if (hasHardDelete && hasSoftDelete) {
      return false;
    }
    if (hasHardDelete !== hasSoftDelete && selectedItems.length > 0) {
      return true;
    }
    return false;
  }

  canHardDelete(selectedItems: { deleteType: string }[]) {
    const hardDelete = selectedItems.filter(
      (item: any) => item.deleteType === 'hard-delete'
    );
    return selectedItems.length === hardDelete.length;
  }

  canUnrestrict(data: SelectedRowData[]): boolean {
    return (
      data.length > 0 && data.every((row) => row['status'] == 'restricted')
    );
  }

  canRestrict(data: SelectedRowData[]): boolean {
    return data.length > 0 && data.every((row) => row['status'] == 'active');
  }

  hasDeleteItems(selectedItems: { deleteType: string }[]) {
    return selectedItems.filter(
      (item: any) => item.deleteType === 'hard-delete'
    ).length > 0
      ? true
      : false;
  }

  canArchive(selectedItems: { archived: boolean }[]) {
    const isArchived =
      selectedItems.filter((item: any) => item.archived).length > 0
        ? true
        : false;
    const isNotArchived =
      selectedItems.filter((item: any) => !item.archived).length > 0
        ? true
        : false;
    const notArchive = selectedItems.filter((item: any) => !item.archived);

    if (isArchived && isNotArchived) return false;
    if (isNotArchived && selectedItems.length === notArchive.length)
      return true;
    return false;
  }

  canDraft(selectedItems: { drafted: boolean }[]) {
    const drafts = selectedItems.filter((item: any) => !item.drafted);
    return selectedItems.length === drafts.length;
  }

  canPublish(selectedItems: { drafted: boolean }[]) {
    const publish = selectedItems.filter((item: any) => item.drafted);
    return selectedItems.length === publish.length;
  }

  isDefaultAvatar(avatar: string): boolean {
    if (avatar.includes(authConfig.defaultAvatar)) return true;
    else return false;
  }

  isDefaultBackgroundImage(image: string): boolean {
    if (image.includes(authConfig.defaultBackgroundImage)) return true;
    else return false;
  }

  checkFileSize({
    fileList,
    maximumFileSize = 10,
    showAlert = true,
  }: CheckFileSizeProp): { error: boolean } {
    let isTooSmall = false;
    const isTooLarge = Object.keys(fileList).find((_, index) => {
      const fileSize = fileList[index].size;
      const fileMb = fileSize / 1024 ** 2;

      if (fileMb === 0) {
        isTooSmall = true;
      }

      if (fileMb > maximumFileSize) {
        return true;
      } else {
        return false;
      }
    });

    if (isTooSmall) {
      if (showAlert) {
        const singleText = `Please ensure the selected file exceeds 0 byte.`;
        const multiText = `Please ensure all of the selected files exceeds 0 byte.`;

        this.dialogService.commonDialog({
          data: { entity: dialogEntities.file, action: 'upload' },
          message: fileList.length > 1 ? multiText : singleText,
          type: 'warning',
        });
      }
      return { error: true };
    }

    if (!isTooSmall && isTooLarge) {
      if (showAlert) {
        const singleText = `Please ensure the selected file does not exceed ${maximumFileSize}MB.`;
        const multiText = `Please ensure none of the selected files exceeds ${maximumFileSize}MB.`;

        this.dialogService.commonDialog({
          data: { entity: dialogEntities.file, action: 'upload' },
          message: fileList.length > 1 ? multiText : singleText,
          type: 'warning',
        });
      }
    }

    return { error: !!isTooLarge };
  }

  sanitizeMeta(meta: any, originalMeta?: any) {
    const allowedFields = [
      'title',
      'description',
      'keywords',
      'canonical_url',
      'robots',
    ];
    const updatedMeta = { ...meta };
    for (const property in meta) {
      if (!allowedFields.includes(property)) {
        delete updatedMeta[property];
      }
      if (allowedFields.includes(property) && !updatedMeta[property]) {
        if (originalMeta && !originalMeta[property])
          delete updatedMeta[property];
        else {
          if (property === 'keywords') updatedMeta[property] = [];
          else updatedMeta[property] = '';
        }
      }
    }

    return updatedMeta;
  }

  getFileDetails(
    files: File[],
    storage_url: string = '',
    index: number,
    name?: string,
    existingData?: any
  ) {
    const file = files[index];
    return {
      storage_url,
      name: file.name,
      size: `${(file.size / 1024).toFixed(2)}kB`,
      type: file.type.split('/')[0],
      created_at: file.lastModified,
      image: storage_url,
      alt: name,
      tempAlt: name,
      title: name,
      tempTitle: name,
      useTempAlt: false,
      useTempTitle: false,
      ...(existingData && existingData),
    };
  }

  updateUploads(uploads: any[], name: string) {
    return uploads.map((upload) => {
      return {
        ...upload,
        tempAlt: name,
        tempTitle: name,
        useTempAlt: true,
        useTempTitle: true,
      };
    });
  }

  formFaqsData(faqs: any) {
    const _faqs = faqs?.map((faq: any) => {
      return {
        ...faq,
        faq_id: faq.id,
        details: faq.faq_details?.map((d: any) =>
          d.uploads.length > 0
            ? { ...d, details_id: d.id, hasImage: true }
            : { ...d, details_id: d.id, hasImage: false }
        ),
      };
    });

    return _faqs;
  }

  updateImageFields(image: any, name: string) {
    if (image.storage_url) {
      return {
        ...image,
        tempAlt: name,
        tempTitle: name,
      };
    }
    if (Array.isArray(image) && image.length > 0) {
      const gallery: any[] = [];
      image.forEach((img: any) => {
        gallery.push({
          ...img,
          tempAlt: name,
          tempTitle: name,
        });
      });
      return gallery;
    }
    return;
  }

  sanitizeImages(images: any) {
    const allowedFields = ['image', 'alt', 'title', 'caption'];

    return images.map((image: any) => {
      const updatedImage = { ...image };
      for (const property in image) {
        if (!allowedFields.includes(property)) {
          delete updatedImage[property];
        }
      }
      return updatedImage;
    });
  }

  sanitizeGallery(gallery: any[]) {
    return gallery.filter((g) => !g.ref);
  }

  addAddressesToEvents = (events: Array<any>) => {
    return events.map((a) => {
      const lat = a['address']?.latitude ?? null;
      const lng = a['address']?.longitude ?? null;
      return {
        ...a,
        address: { ...a['address'], lat: Number(lat), lng: Number(lng) },
      };
    });
  };

  constructGallery(property: any) {
    const gallery: Photo[] | Video[] = [];
    property?.map((m: any) => {
      if (m.storage_url) {
        gallery.push({
          ...m,
          src: m.storage_url,
        });
      }
    });

    return gallery;
  }

  getFilterValidations(filters: any) {
    const keys = Object.keys(filters);
    const blacklist = ['per_page', 'page'];
    const filteredKeys = keys.filter((key) => !blacklist.includes(key));

    if (filteredKeys.length > 0) {
      return 'No events match the search!';
    } else {
      return '';
    }
  }

  getCountryFromCode(
    code: CountryCode | undefined | string,
    countries: Country[] = Countries as Country[]
  ): Country {
    return countries.find((country) => country.iso2 == code) as Country;
  }

  getCountryByName(
    countryName: string,
    countries: Country[] = Countries as Country[]
  ): Country | undefined {
    return countries.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
  }

  updateCountriesChart(top_countries_views: any) {
    const countries: string[] = [];
    const counts: number[] = [];
    top_countries_views?.map((country: any) => {
      countries.push(country.country);
      counts.push(country.countries_count);
    });

    const data = {
      series: [
        {
          name: 'Value',
          data: counts.reverse(),
        },
      ],
      lables: countries,
    };

    return counts.length > 0 ? data : undefined;
  }

  updateDevicesChart(top_devices_views: any) {
    const data: any[] = [];

    top_devices_views?.map((device: any) => {
      if (device.device_type === 'desktop') {
        data.push({
          label: 'Desktop',
          value: device.total_views,
          color: '#2A85FF',
          icon: 'assets/icons/laptop-light.svg',
        });
      }
      if (device.device_type === 'mobile') {
        data.push({
          label: 'Mobile',
          value: device.total_views,
          color: '#8E59FF',
          icon: 'assets/icons/mobile-light.svg',
        });
      }
      if (device.device_type === 'tablet') {
        data.push({
          label: 'Tablet',
          value: device.total_views,
          color: '#83BF6E',
          icon: 'assets/icons/tablet-light.svg',
        });
      }
    });

    return data;
  }

  observeDOMElementOnMount(
    selector: string,
    selectorType: 'tagName' | 'className' | 'id',
    callBack: (data: any) => void,
    targetNode = document.body
  ) {
    const observerOptions = {
      childList: true,
      subtree: true,
    };

    function trackChanges(records: any, observer: MutationObserver) {
      for (const record of records) {
        for (const addedNode of record.addedNodes) {
          if (
            addedNode[selectorType] ===
            (selectorType == 'tagName' ? selector.toUpperCase() : selector)
          ) {
            callBack(addedNode);
            observer.disconnect();
          }
        }
      }
    }

    if (!this.isBrowser) return;
    const observer = new MutationObserver(trackChanges);
    observer.observe(targetNode, observerOptions);
  }

  toggleCaptchaBadge(show: boolean) {
    const updateStyles = () => {
      const badge = document.getElementsByClassName('grecaptcha-badge')[0];

      if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = show ? 'visible' : 'hidden';
        badge.style.bottom = show ? '14px' : '-60px';
      }
    };

    if (!show) return updateStyles();

    const badge = document.getElementsByClassName('grecaptcha-badge')[0];

    if (!badge) {
      this.observeDOMElementOnMount(
        'grecaptcha-badge',
        'className',
        updateStyles
      );
    } else updateStyles();
  }
}
