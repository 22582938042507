import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiConfig } from 'src/app/configs/api.config';
import { CoreService } from '../core.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private core: CoreService, private apiService: ApiService) { }

  /** GET: Fetch current user details
   */
  getCurrentUser(): Promise<any> {
    const url = `${apiConfig.base}/users/current`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Fetch all user details
   * @param {string} ref reference code of the user
   */
  getUser(ref: string): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/show`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Fetch all user details
   * @param {string} email email of the user
   */
  getUserByEmail(email: string): Promise<any> {
    const url = `${apiConfig.portal}/users/${email}/_show`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Show information for creating.
   */
  getCreateUser(): Promise<any> {
    const url = `${apiConfig.portal}/users/create`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Show specific user's account information for editing.
   * @param {string} ref reference code of the user
   */
  getEditUser(ref: string): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/edit`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Get settings information for a user.
   * @param {string} ref reference code of the user
   */
  getUserSettings(ref: string): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/settings`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Fetch all users stats
   * @param {any} filterOptions Details of these query options can be found
   * here https://test.sportforcharity.com/#user-GETapi-v1-portal-users-stats-summary
   */
  getUserStats(filterOptions?: any): Promise<any> {
    let url = `${apiConfig.portal}/users/stats/summary`;

    let params = new HttpParams();

    // Any parameter is passed only if it is not empty or null
    if (!this.core.isEmptyOrNull(filterOptions)) {
      params = params.appendAll(filterOptions);
      url += '?' + params.toString();
    }

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Fetch all users
   * @param {any} filterOptions Details of these query options can be found
   * here https://test.sportforcharity.com/#user-GETapi-v1-portal-users-stats-chart
   */
  getRegistrationStats(filterOptions?: any): Promise<any> {
    let url = `${apiConfig.portal}/users/stats/chart`;

    let params = new HttpParams();

    // Any parameter is passed only if it is not empty or null
    if (!this.core.isEmptyOrNull(filterOptions)) {
      params = params.appendAll(filterOptions);
      url += '?' + params.toString();
    }

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** GET: Fetch all users
   * @param {any} filterOptions Details of these query options can be found
   * here https://test.sportforcharity.com/#user-GETapi-v1-portal-users
   */
  getUsers(filterOptions?: any): Promise<any> {
    let url = `${apiConfig.portal}/users`;

    let params = new HttpParams();

    // Any parameter is passed only if it is not empty or null
    if (!this.core.isEmptyOrNull(filterOptions)) {
      params = params.appendAll(filterOptions);
      url += '?' + params.toString();
    }

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** Get: Admin login as user
   * @param {string} ref reference code of the user
   */
  adminLoginAs(ref: string): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/admin-login-as`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** POST: Create user
   * @param {any} requestBody Request bodys
   */
  createUser(requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/store`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** PUT: Update user
   * @param {string} ref reference code of the user
   * @param {any} requestBody Request bodys
   */
  updateUser(ref: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/update`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePutRequest(url, body);
  }

  /** PATCH: Update personal info
   * @param {string} ref reference code of the user
   * @param {any} requestBody Request bodys
   */
  updatePersonalInfo(ref: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/update-personal-info`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }

  /** POST: Update user password
   * @param {string} ref reference code of the user
   * @param {any} requestBody Request bodys
   */
  updatePassword(ref: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/users/${ref}/update-password`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }

  /** Patch: Update user role
   * @param {string} ref reference code of the user
   * @param {any} requestBody Request bodys
   */
  updateRole(ref: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${ref}/update-roles`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }

  /** Patch: Update user site action
   * @param {any} requestBody contains user_ids and action to be applied
   */
  updateUserAction(requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/take-action`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }

  /** Patch: Update user avatar
   * @param {string} profileRef reference code of the user's profile
   * @param {any} requestBody Request bodys
   */
  updateAvatar(profileRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/profile/${profileRef}/avatar-upload`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** Get: Remove user avatar
   * @param {string} profileRef reference code of the user's profile
   */
  removeAvatar(profileRef: string): Promise<any> {
    const url = `${apiConfig.base}/profile/${profileRef}/avatar-delete`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** Patch: Update user background image
   * @param {string} profileRef reference code of the user's profile
   * @param {any} requestBody Request bodys
   */
  updateBackgroundImage(profileRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.base}/profile/${profileRef}/background-image-upload`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** Get: Remove user background image
   * @param {string} profileRef reference code of the user's profile
   */
  removeBackgroundImage(profileRef: string): Promise<any> {
    const url = `${apiConfig.base}/profile/${profileRef}/background-image-delete`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** Put: Update socials info
   * @param {string} userRef reference code of the user
   * @param {any} requestBody Request bodys
   */
  updateSocials(userRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${userRef}/update-socials`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePutRequest(url, body);
  }

  /** DELETE: Remove socials info
   * @param {string} userRef reference code of the user
   * @param {any} requestBody Request bodys
   */
  removeSocials(userRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${userRef}/delete-socials`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makeDeleteRequest(url, body);
  }

  /** PATCH: Switch active role
   * @param {string} userRef reference code of the user
   * @param {any} requestBody Request bodys
   */
  switchActiveRole(userRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${userRef}/switch-active-role`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }

  /** GET: Restore users
   * @param {string} requestBody contains user_ids to be restored
   */
  restoreUsers(requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/restore`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePostRequest(url, body);
  }

  /** DELETE: Delete users
   * @param {string} requestBody contains user_ids to be deleted
   * @param {string} force Optional parameter to indicate that user record should be deleted permanently
   * @param {string} confirmation Confirm permanent delete
   */
  deleteUsers(
    requestBody: any,
    force: string = '',
    confirmation?: string
  ): Promise<any> {
    let url = `${apiConfig.portal}/users/delete`;

    // Any parameter is passed only if it is not empty or null
    if (!this.core.isEmptyOrNull(force)) {
      url += '?force=' + force;
    }
    if (confirmation) {
      url += '?' + confirmation;
    }

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makeDeleteRequest(url, body);
  }

  exportUsers(filterOptions?: any): Promise<any> {
    let url = `${apiConfig.portal}/users/export`;
    let params = new HttpParams();

    if (!this.core.isEmptyOrNull(filterOptions)) {
      params = params.appendAll(filterOptions);
      url += '?' + params.toString();
    }
    //caution: passing the options invalidates the form data
    return this.apiService.makeFileRequest(url);
  }
}
