// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domain: 'portal-rtgp-dev.sportsmediaagency.com',
  client: '4c715bbaa1f602407e602191b46b08bb178e75ba98647fd7e4792a280e4d4432',
  palette: 'default',
  name: 'Running Grand Prix Portal',
  caption: 'A UK wide 5K, 10K, Half Marathon and Marathon series at Goodwood',
  page: {
    titlePrefix: 'Running Grand Prix',
    author: 'Running Grand Prix',
    publisher: 'Running Grand Prix',
    robots: 'index, follow'
  },
  website: {
    url: 'https://rtgp-dev.sportsmediaagency.com'
  },
  portal: {
    url: '/',
  },
  recaptcha: {
    siteKey: '6LdJoZ4eAAAAAA-80TERE0a22WIDk5Q6sSFDY3Kd',
  },
  google: {
    apiKey: 'AIzaSyCg1FnzwEd0ijbAH9bF3Swx5AXs2Vu_0BY',
    measurementId: 'G-DXEWVB9JFJ',
  },
  stripe: {
    publishableKey: 'pk_test_51PJbi5P43ZWYD6rjoxfzUacARbjg7N3608lRe9AuenKITBGt8dnm6pIGJaElUvoIWJpKhuadXHTXr2nAFMgUlDMI006Y4s1PbA',
  },
  api: {
    base: 'https://api-dev.sportsmediaagency.com/api/v1',
    client: 'https://api-dev.sportsmediaagency.com/api/v1/client',
    portal: 'https://api-dev.sportsmediaagency.com/api/v1/portal',
    public: 'https://api-dev.sportsmediaagency.com',
  },
  auth: {
    logoutPath: '/auth/login',
  },
  socials: {
    google: {
      clientKey: "1047537031287-eaqd28r2i5ftmt0qcrg2vqhnrtf1nk7p.apps.googleusercontent.com",
      authCallback: 'https://api-dev.sportsmediaagency.com/auth/redirect?provider=google&key=3cdb9d301e05299ae3c15a78835c787f&source=portal-rtgp-api-dev.sportsmediaagency.com',
      configOptions: {
        oneTapEnabled: false, // default is true
        scopes: 'https://www.googleapis.com/auth/userinfo.profile',
        prompt: 'none'
      }
    },
    facebook: {
      clientKey: '1454754138326444',
      authCallback: 'https://api-dev.sportsmediaagency.com/auth/redirect?provider=facebook&key=3cdb9d301e05299ae3c15a78835c787f&source=portal-rtgp-api-dev.sportsmediaagency.com',
    },
    twitter: {
      authCallback: '',
      handle: 'runthroughuk',
    },
    linkedin: {
      authCallback: ''
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
