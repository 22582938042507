import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { ThemeService } from 'src/app/theme/theme.service';
import { scrollbarStyle } from 'src/assets/constants';
//import { Router, NavigationEnd } from '@angular/router';
//import { filter } from 'rxjs/operators';
//import { googleConfig } from './configs/google.config';
import { AuthService } from './services/api/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from './services/dialog.service';

//declare let gtag: (...rest: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'portal.runninggrandprix';

  constructor(
    private themeService: ThemeService,
    private coreService: CoreService,
    private authService: AuthService,
    private swUpdate: SwUpdate,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.checkForUpdate();

    this.themeService.getSystemTheme();
    this.coreService.setSystemLanguage();
    this.setPlatformUser();

    const navigator: any = window.navigator;
    if (navigator['userAgentData']?.platform !== 'macOS') {
      const scrollBarCssFile = document.createElement('style');
      scrollBarCssFile.textContent = scrollbarStyle;
      document.head.appendChild(scrollBarCssFile);
    }

    /*if (googleConfig.measurementId) {
      // set up google analytics if the measurement id has been properly configured for the project (runtime environment)
      this.setupGoogleAnalytics();
    }*/
  }

  /*setupGoogleAnalytics() {
    if (!this.coreService.isBrowser) return
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag('config', googleConfig.measurementId, {
          page_path: event.urlAfterRedirects,
        });
      });
  }*/

  setPlatformUser() {
    if (!this.coreService.isBrowser) return;
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.storageArea?.getItem('platform') == null)
        this.generateIdentifier();
    });

    if (this.coreService.isEmptyOrNull(this.authService.platformUser)) {
      this.generateIdentifier();
    }
  }

  generateIdentifier() {
    this.authService
      .generateIdentifier()
      .then((response) => {
        const user = { identifier: response.data.identifier };
        this.authService.platformUser = { user };
      })
      .catch((error) => {
        console.error(error);
      });
  }

  checkForUpdate(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.swUpdate.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          this.dialogService
            .commonDialog({
              message:
                'You are using an older version of the application. Please reload for an improved experience.',
              type: 'info',
              customButtons: {
                confirm: {
                  text: `Reload`,
                  value: true,
                  visible: true,
                  closeModal: false,
                },
              },
            })
            .then(() => {
              this.applyUpdate();
            });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
        case 'NO_NEW_VERSION_DETECTED':
          console.log(
            `No new version detected '${evt.version.hash}': ${evt.type}`
          );
          break;
      }
    });
  }

  applyUpdate(): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch((error) => console.error('Failed to apply updates:', error));
  }
}
