export const dialogConfig = [
  { singular: 'user', plural: 'users' },
  { singular: 'user stats', plural: 'users stats' },
  { singular: 'user chart', plural: 'users charts' },
  { singular: 'user settings', plural: 'users settings' },
  { singular: 'profile picture', plural: 'profile pictures' },
  { singular: 'backgorund image', plural: 'backgorund images' },
  { singular: 'role', plural: 'roles' },
  { singular: 'event', plural: 'events' },
  { singular: 'event stats', plural: 'event stats' },
  { singular: 'event chart', plural: 'event charts' },
  { singular: 'distance', plural: 'distances' },
  { singular: 'distance stats', plural: 'distance stats' },
  { singular: 'region', plural: 'regions' },
  { singular: 'region stats', plural: 'region stats' },
  { singular: 'experience', plural: 'experiences' },
  { singular: 'experience stats', plural: 'experience stats' },
  { singular: 'entry', plural: 'entries' },
  { singular: 'entry stats', plural: 'entries stats' },
  { singular: 'entry chart', plural: 'entries chart' },
  { singular: 'setting', plural: 'settings' },
  { singular: 'city', plural: 'cities' },
  { singular: 'city stats', plural: 'city stats' },
  { singular: 'venue', plural: 'venues' },
  { singular: 'venue stats', plural: 'venue stats' },
  { singular: 'enquiry', plural: 'enquiries' },
  { singular: 'enquiry stats', plural: 'external stats' },
  { singular: 'enquiry chart', plural: 'external charts' },
  { singular: 'external enquiry', plural: 'external enquiries' },
  { singular: 'external enquiry stats', plural: 'external enquiry stats' },
  { singular: 'external enquiry chart', plural: 'external enquiry charts' },
  { singular: 'invoice', plural: 'invoices' },
  { singular: 'invoice item', plural: 'invoice items' },
  { singular: 'invoice stats', plural: 'invoice stats' },
  { singular: 'invoice chart', plural: 'invoice charts' },
  { singular: 'page', plural: 'pages' },
  { singular: 'page stats', plural: 'page stats' },
  { singular: 'partner', plural: 'partners' },
  { singular: 'partner channel', plural: 'partner channels' },
  { singular: 'partner stats', plural: 'partner stats' },
  { singular: 'participant', plural: 'participants' },
  { singular: 'participant stats', plural: 'participant stats' },
  { singular: 'participant chart', plural: 'participant charts' },
  { singular: 'image', plural: 'images' },
  { singular: 'section', plural: 'sections' },
  { singular: 'FAQ', plural: 'FAQs' },
  { singular: 'question', plural: 'questions' },
  { singular: 'site', plural: 'sites' },
  { singular: 'charity', plural: 'charities' },
  { singular: 'channel', plural: 'channels' },
  { singular: 'resale request', plural: 'resale requests' },
  { singular: 'event place invoice', plural: 'event place invoices' },
  { singular: 'charity partner package', plural: 'charity partner packages' },
  { singular: 'charity membership', plural: 'charity memberships' },
  { singular: 'series', plural: 'series' },
  { singular: 'series stats', plural: 'series stats' },
  { singular: 'sponsor', plural: 'sponsors' },
  { singular: 'sponsor stats', plural: 'sponsor stats' },
  { singular: 'custom field', plural: 'custom fields' },
  { singular: 'medal', plural: 'medals' },
  { singular: 'medal stats', plural: 'medal stats' },
  { singular: 'partner event', plural: 'partner events' },
  { singular: 'requirement', plural: 'requirements' },
  { singular: 'dashboard stats', plural: 'dashboard stats' },
  { singular: 'dashboard chart', plural: 'dashboard charts' },
  { singular: 'upcoming event', plural: 'upcoming events' },
  { singular: 'combination', plural: 'combinations' },
  { singular: 'combination stats', plural: 'combination stats' },
  { singular: 'payment', plural: 'payments' },
  { singular: 'password', plural: 'passwords' },
  { singular: 'reset code', plural: 'reset codes' },
  { singular: 'cart', plural: 'carts' },
  { singular: 'wishlist', plural: 'wishlists' },
  { singular: 'redirect', plural: 'redirects' },
  { singular: 'charity category', plural: 'charity categories' },
  { singular: 'file', plural: 'files' },
  { singular: 'social', plural: 'social' },
  { singular: 'meta data', plural: 'meta data' },
  { singular: 'post', plural: 'posts' },
  { singular: 'checkout', plural: 'checkouts' },
  { singular: 'infinite balance', plural: 'infinite balances' },
  { singular: 'finite balance', plural: 'finite balances' },
  { singular: 'account history', plural: 'account history' },
  { singular: 'wallet balance', plural: 'wallet balance' },
];

export const dialogEntities = {
  user: 'user',
  userStats: 'user stats',
  userChart: 'user chart',
  userSettings: 'user settings',
  userProfilePicture: 'profile picture',
  userBackgroundImage: 'backgorund image',
  role: 'role',
  event: 'event',
  eventStats: 'event stats',
  eventChart: 'event chart',
  category: 'distance',
  categoryStats: 'distance stats',
  region: 'region',
  regionStats: 'region stats',
  experience: 'experience',
  experienceStats: 'experience stats',
  entry: 'entry',
  entryStats: 'entry stats',
  entryChart: 'entry chart',
  setting: 'setting',
  city: 'city',
  cityStats: 'city stats',
  venue: 'venue',
  venueStats: 'venue stats',
  enquiry: 'enquiry',
  enquiryStats: 'enquiry stats',
  enquiryChart: 'enquiry chart',
  externalEnquiry: 'external enquiry',
  externalEnquiryStats: 'external enquiry stats',
  externalEnquiryChart: 'external enquiry chart',
  invoice: 'invoice',
  invoiceItem: 'invoice item',
  invoiceStats: 'invoice stats',
  invoiceChart: 'invoice chart',
  page: 'page',
  pageStats: 'page stats',
  partner: 'partner',
  partnerChannel: 'partner channel',
  partnerStats: 'partner stats',
  participant: 'participant',
  participantStats: 'participant stats',
  participantChart: 'participant chart',
  image: 'image',
  section: 'section',
  FAQ: 'FAQ',
  question: 'question',
  site: 'site',
  charity: 'charity',
  channel: 'channel',
  resaleRequest: 'resale request',
  eventPlaceInvoice: 'event place invoice',
  charityPartnerPackage: 'charity partner package',
  charityMembership: 'charity membership',
  series: 'series',
  seriesStats: 'series stats',
  sponsor: 'sponsor',
  sponsorStats: 'sponsor stats',
  customField: 'custom field',
  medal: 'medal',
  medalStats: 'medal stats',
  partnerEvent: 'partner event',
  requirement: 'requirement',
  dashboardStats: 'dashboard stats',
  dashboardChart: 'dashboard chart',
  upcomingEvent: 'upcoming event',
  combination: 'combination',
  combinationStats: 'combination stats',
  payment: 'payment',
  password: 'password',
  resetCode: 'reset code',
  cart: 'cart',
  wishlist: 'wishlist',
  redirect: 'redirect',
  charityCategory: 'charity category',
  file: 'file',
  social: 'social',
  metaData: 'meta data',
  post: 'post',
  checkout: 'checkout',
  walletBalance: 'wallet balance',
  infiniteBalance: 'infinite balance',
  finiteBalance: 'finite balance',
  accountHistory: 'account history',
};
