import { Theme } from './theme.interface';

export const themes: Array<Theme> = [
  {
    default: {
      light: {
        '--primary-01': '#004225',
        '--primary-01-01': '#006f3f',
        '--primary-01-02': 'rgba(0, 66, 37, 0.35)',
        '--primary-01-03': '#2881FF',
        '--primary-01-04': '#CCE4F3',
        '--primary-01-05': '#3395cf',
        '--primary-02': '#83BF6E',
        '--primary-02-01': 'rgba(131, 191, 110, 0.2)',
        '--primary-03': '#FF6A55',
        '--primary-03-01': 'rgba(255, 188, 153, 0.25)',
        '--primary-04': '#8E59FF',
        '--secondary-01': '#FFBC99',
        '--secondary-01-01': '#FFBC9995',
        '--secondary-02': '#CABDFF',
        '--secondary-03': '#B1E5FC',
        '--secondary-03-01': 'rgba(177, 229, 252, 0.2)',
        '--secondary-04': '#B5E4CA',
        '--secondary-05': '#FFC554',
        '--secondary-05-01': '#ffc107',
        '--secondary-06': '#fd8b2c',
        '--secondary-07': '#fd7e14',

        '--neutral-00': '#FFFFFF',
        '--neutral-01': '#FCFCFC',
        '--neutral-02': '#F4F4F4',
        '--neutral-03': '#EFEFEF',
        '--neutral-03-01': '#DBDBDB',
        '--neutral-04': '#6F767E',
        '--neutral-05': '#33383F',
        '--neutral-06': '#272B30',
        '--neutral-07': '#1A1D1F',
        '--neutral-08': '#111315',
        '--neutral-13': '#555555',

        '--neutral-09': 'rgba(244, 244, 244, 0.8)',
        '--neutral-10': '#777E90',
        '--neutral-11': '#FFFFFF',
        '--neutral-12': '#1A1D1F',

        '--neutral-shade-04-75': '#9A9FA5',
        '--neutral-shade-04-40': '#9A9FA540',
        '--neutral-shade-dark-04-50': '#6F767E',
        '--neutral-shade-dark-04-40': 'rgba(111, 118, 126, 0.4)',
        '--neutral-shade-dark-08-50': 'rgba(17, 19, 21, 0.5)',
        '--neutral-shade-dark-08-80': 'rgba(17, 19, 21, 0.8)',

        '--social-facebook': '#4267b2',
        '--social-twitter': '#00ACEE',
        '--social-instagram': '#dc2743',
        '--social-pinterest': '#e60023',
        '--social-linkedin': '#0a58ca',
        '--social-google': '#db4a39',

        '--danger-01': '#e35d6a',
        '--danger-01-01': '#dc3545',
        '--danger-01-02': '#f5c2c7',
        '--success-01': '#83BF6E',
        '--success-01-01': '#139c49',
        '--success-01-02': '#69b150',
        '--success-01-03': '#EAFAE5',
        '--warning-01': '#e4a11d',
        '--warning-01-01': '#d9991a',
        '--info-01': '#54b4d3',
        '--info-01-01': '#50abc8',
      },
      dark: {
        '--primary-01': '#004225',
        '--primary-01-01': '#006f3f',
        '--primary-01-02': 'rgba(0, 66, 37, 0.35)',
        '--primary-01-03': '#2881FF',
        '--primary-01-04': '#CCE4F3',
        '--primary-01-05': '#3395cf',
        '--primary-02': '#83BF6E',
        '--primary-02-01': 'rgba(131, 191, 110, 0.2)',
        '--primary-03': '#FF6A55',
        '--primary-03-01': 'rgba(255, 188, 153, 0.25)',
        '--primary-04': '#8E59FF',
        '--secondary-01': '#FFBC99',
        '--secondary-01-01': '#FFBC9995',
        '--secondary-02': '#CABDFF',
        '--secondary-03': '#B1E5FC',
        '--secondary-03-01': 'rgba(177, 229, 252, 0.2)',
        '--secondary-04': '#B5E4CA',
        '--secondary-05': '#FFC554',
        '--secondary-05-01': '#ffc107',
        '--secondary-06': '#fd8b2c',
        '--secondary-07': '#fd7e14',

        '--neutral-00': '#111315',
        '--neutral-01': '#1A1D1F',
        '--neutral-02': '#272B30',
        '--neutral-03': '#33383F',
        '--neutral-03-01': '#6F767E',
        '--neutral-04': '#DBDBDB',
        '--neutral-05': '#EFEFEF',
        '--neutral-06': '#F4F4F4',
        '--neutral-07': '#FCFCFC',
        '--neutral-08': '#FFFFFF',
        '--neutral-13': '#aaaaaa',

        '--neutral-09': 'rgba(39, 43, 48, 0.9)',
        '--neutral-10': '#777E90',
        '--neutral-11': '#FFFFFF',
        '--neutral-12': '#1A1D1F',

        '--neutral-shade-04-75': '#9A9FA5',
        '--neutral-shade-04-40': '#9A9FA540',
        '--neutral-shade-dark-04-50': '#6F767E',
        '--neutral-shade-dark-04-40': 'rgba(111, 118, 126, 0.4)',
        '--neutral-shade-dark-08-50': 'rgba(17, 19, 21, 0.5)',
        '--neutral-shade-dark-08-80': 'rgba(17, 19, 21, 0.8)',

        '--social-facebook': '#4267b2',
        '--social-twitter': '#00ACEE',
        '--social-instagram': '#dc2743',
        '--social-pinterest': '#e60023',
        '--social-linkedin': '#0a58ca',
        '--social-google': '#db4a39',

        '--danger-01': '#e35d6a',
        '--danger-01-01': '#dc3545',
        '--danger-01-02': '#f5c2c7',
        '--success-01': '#83BF6E',
        '--success-01-01': '#139c49',
        '--success-01-02': '#69b150',
        '--success-01-03': '#EAFAE5',
        '--warning-01': '#e4a11d',
        '--warning-01-01': '#d9991a',
        '--info-01': '#54b4d3',
        '--info-01-01': '#50abc8',
      },
    },
  },
];
